<script setup lang="ts">
import type { Ref } from 'vue';
import type { BackgroundType } from '~/types/components/backgroundType';
import type { ComponentSize } from '~/types/components/componentSize';

defineOptions({ inheritAttrs: false });
const emit = defineEmits<{
    (e: 'update:modelValue', value: string | number | undefined): void;
}>();

const props = withDefaults(
    defineProps<{
        id: string;
        label?: string;
        modelValue: string | number | undefined;
        type?: string;
        name: string;
        showLabel?: boolean;
        backgroundType?: BackgroundType;
        required?: boolean;
        disabled?: boolean;
        size?: ComponentSize;
        error?: boolean;
        errorMsg?: string | Ref<string>;
        maska?: boolean;
    }>(),
    {
        label: '',
        type: 'text',
        showLabel: false,
        backgroundType: 'light',
        required: undefined,
        disabled: undefined,
        size: 'base',
        error: false,
        errorMsg: '',
        maska: false,
    },
);

const updateInput = (event: Event) => {
    let value;
    if (!(event.target as HTMLInputElement).value) {
        value = undefined;
    } else if (props.type === 'number') {
        value = parseInt((event.target as HTMLInputElement).value) as number;
    } else {
        value = (event.target as HTMLInputElement).value as string;
    }

    emit('update:modelValue', value);
};

const inputRef: Ref<HTMLInputElement | null> = ref(null);

defineExpose({
    focus: () => {
        inputRef.value?.focus();
    },
    blur: () => {
        inputRef.value?.blur();
    },
    inputRef,
});
</script>

<template>
    <div :class="$attrs.class">
        <label
            v-if="label !== ''"
            :for="id"
            class="block mb-2"
            :class="{
                'sr-only': !showLabel,
                'border-brand-danger': error,
            }"
        >
            {{ label }}<span v-if="required"> *</span>
        </label>
        <div
            class="flex items-center w-full border focus-within:shadow-inner placeholder-gray-200 focus-within:outline-none"
            :class="[
                $attrs.class && typeof $attrs.class === 'string' && $attrs.class.includes('rounded')
                    ? $attrs.class
                    : 'rounded-md',
                {
                    'bg-white': backgroundType === 'dark',
                    'opacity-70': backgroundType === 'dark' && disabled,
                    'bg-gray-100': backgroundType === 'light' && !disabled,
                    'bg-transparent': backgroundType === 'light' && disabled,
                    'text-gray-200 cursor-not-allowed border-gray-200': disabled,
                },
                error ? 'border-brand-danger' : 'border-gray-100',
            ]"
        >
            <slot name="prepend" />
            <input
                v-if="!maska"
                v-bind="$attrs"
                :id="id"
                ref="inputRef"
                class="flex items-center w-full placeholder-gray-200 bg-transparent border-none focus:outline-none"
                :class="[
                    {
                        'py-2': size === 'base',
                        'py-1.5': size === 'sm',
                        'px-4': type !== 'number',
                        'pl-4': type === 'number',
                        'pr-4':
                            type === 'number' &&
                            $attrs.class &&
                            typeof $attrs.class === 'string' &&
                            $attrs.class.includes('text-right'),
                        'cursor-not-allowed': disabled,
                    },
                ]"
                :type="type"
                :value="modelValue"
                :name="name"
                :required="required"
                :disabled="disabled"
                @input="updateInput"
            />
            <input
                v-else
                v-bind="$attrs"
                :id="id"
                v-maska
                class="flex items-center w-full placeholder-gray-200 bg-transparent border-none focus:outline-none"
                :class="[
                    {
                        'py-2': size === 'base',
                        'py-1.5': size === 'sm',
                        'px-4': type !== 'number',
                        'pl-4': type === 'number',
                        'pr-4':
                            type === 'number' &&
                            $attrs.class &&
                            typeof $attrs.class === 'string' &&
                            $attrs.class.includes('text-right'),
                        'cursor-not-allowed': disabled,
                    },
                ]"
                :type="type"
                :value="modelValue"
                :name="name"
                :required="required"
                :disabled="disabled"
                @input="updateInput"
            />
            <slot name="append" />
        </div>
    </div>
    <div
        v-if="error && errorMsg"
        class="pt-1 text-sm text-brand-danger leading-4 col-span-full"
    >
        <slot
            name="errorMsg"
            :error-msg="errorMsg"
        >
            {{ errorMsg }}
        </slot>
    </div>
</template>
